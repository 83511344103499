@import "../../foundation/setting";



/*上書き
---------------------------------------------------------- */
.l-content {
	@include mq('max') {
		padding-top: 2rem;
	}
}

.l-content-row {
	flex-direction: inherit;
	margin-bottom: 8rem;
	@include mq('max') {
		margin-bottom: 5rem;
	}
}

.l-content-side {
	@include mq('max') {
		margin-top: 0;
		margin-bottom: 5rem;
	}
}



/*検索
---------------------------------------------------------- */
.search-suggest {
	position: relative;
	z-index: 1;
	@include mq('min') {
		color: $color-white;
		background-image: url(../../../img/home/bg_search-suggest.jpg);
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			background-color: rgba($color-blacktone01, 0.1);
		}
	}
	@include mq('max') {
		background-color: $color-white;
	}
	.c-tab__contents {
		padding: 4rem 0 3rem;
		@include mq('max') {
			padding: 2.4rem 1rem;
			padding-bottom: 0;
		}
	}
	.c-tab__triggers {
		@include mq('max') {
			display: block;
		}
	}
	.c-tab__trigger {
		@include mq('max') {
			flex: none;
			width: 100%;
			&:not(:first-child) {
				margin-top: 0.15rem;
			}
		}
	}
	.c-tab__trigger[data-tab="true"] {
		.c-tab__trigger-inner {
			background-color: rgba($color-main, 0.85);
			@include mq('min') {
				&:hover {
					background-color: rgba($color-graytone04, 0.85);
					&:after {
						border-top-color: rgba($color-graytone04, 0.85);
					}
				}
			}
			@include mq('max') {
				background-color: $color-main;
			}
			&:after {
				border-top: 11px solid rgba($color-main, 0.85);
				@include mq('max') {
					display: none;
				}
			}
		}
	}
	.c-tab__trigger-inner {
		background-color: rgba($color-white, 0.85);
		@include mq('min') {
			&:hover {
				background-color: rgba($color-graytone04, 0.85);
			}
		}
		@include mq('max') {
			font-size: 1.4rem;
			color: $color-main;
			border: 2px solid $color-main;
		}
	}
	.c-tab__contents {
		background-color: transparent;
	}
}

.search-suggest__inner {
	padding: 6rem 0;
	@include mq('max') {
		padding: 2.5rem 0;
	}
}

.search-suggest__text {
	margin-bottom: 2rem;
	@include mq('min') {
		text-align: center;
	}
	@include mq('max') {
		margin-bottom: 1.5rem;
	}
}

.search-suggest__form-inner {
	display: flex;
	position: relative;
}

.search-suggest__form-input {
	overflow: hidden;
	width: 100%;
	height: 58px;
	border: 2px solid $color-main;
	background-color: $color-white;
	@include mq('max') {
		height: 50px;
		border-color: $color-graytone03;
	}
	input {
		width: 100%;
		height: 100%;
		padding: 20px;
		padding-right: 60px;
		-webkit-appearance: none;
		border-radius: 0;
		outline: 0;
		border: none;
		color: $color-blacktone01;
		background-color: transparent;
		@include mq('max') {
			padding: 10px;
			padding-right: 45px;
			border-width: 1px;
		}
	}
}

.search-suggest__form-input input::-webkit-input-placeholder {
	color: $color-graytone01;
}

.search-suggest__form-input input:-ms-input-placeholder {
	color: $color-graytone01;
}

.search-suggest__form-input input::-moz-placeholder {
	color: $color-graytone01;
}

.search-suggest__form-button {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	width: 6rem;
	height: 100%;
	text-align: center;
	@include mq('max') {
		width: 4rem;
	}
	.c-icon-search {
		position: relative;
		top: 2px;
		font-size: 2.4rem;
		color: rgba($color-graytone01, 0.8);
		@include mq('max') {
			font-size: 1.7rem;
			color: $color-main;
		}
	}
}




/*データベース
---------------------------------------------------------- */
.database__headline {
	margin-bottom: 2rem;
	font-size: 2.1rem;
	font-weight: bold;
	@include line-height(30, 21);
	text-align: center;
	color: $color-main;
	@include mq('max') {
		font-size: 1.9rem;
	}
	.c-icon-database {
		position: relative;
		top: 2px;
		margin-right: 2rem;
		@include mq('max') {
			top: 1px;
			margin-right: 1.5rem;
			font-size: 1.8rem;
		}
	}
}

.database__list-wrap {
	padding: 2rem;
	background-color: $color-graytone04;
	@include mq('max') {
		padding: 2rem 0;
	}
}

.database__list {
	display: flex;
	flex-wrap: wrap;
	margin: -7px 0 0 -7px;
	@include mq('max') {
		margin: -1px 0 0 -1px;
	}
}

.database__item {
	display: flex;
	width: calc(33.3333% - 7px);
	min-height: 60px;
	margin: 7px 0 0 7px;
	@include mq('max') {
		width: calc(50% - 1px);
		min-height: 50px;
		margin: 1px 0 0 1px;
		&:last-child:nth-child(odd) {
			width: calc(100% - 1px);
		}
	}
}

.database__item-link {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: inherit;
	padding: 1rem;
	@include line-height(21, 16);
	color: #666;
	background-color: $color-white;
	transition: 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
	transition-property: color, background-color;
	@include mq('min') {
		&:hover {
			color: $color-white;
			background-color: $color-main;
		}
	}
}


/*ローカルナビ
---------------------------------------------------------- */
.lnav__list {
	display: flex;
	flex-wrap: wrap;
	margin-top: -4px;
	margin-left: -4px;
}

.lnav__item {
	width: calc(50% - 4px);
	margin-top: 4px;
	margin-left: 4px;
}

.lnav__item-link {
	display: flex;
	position: relative;
	z-index: 1;
	width: 100%;
	min-height: 8.2rem;
	@include mq('min') {
		&:hover:after {
			background-color: rgba($color-blacktone01, 0.6);
		}
	}
	@include mq('max') {
		min-height: 7.7rem;
	}
	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}
	&[data-page="floor-main"] {
		&:before {
			background-image: url(../../../img/home/bg_lnav-floor-main_pc.jpg);
			@include mq('max') {
				background-image: url(../../../img/home/bg_lnav-floor-main_sp.jpg);
			}
		}
	}
	&[data-page="floor-medicine"] {
		&:before {
			background-image: url(../../../img/home/bg_lnav-floor-medicine_pc.jpg);
			@include mq('max') {
				background-image: url(../../../img/home/bg_lnav-floor-medicine_sp.jpg);
			}
		}
	}
	// &:after {
	// 	content: "";
	// 	display: block;
	// 	width: 100%;
	// 	height: 100%;
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	z-index: -1;
	// 	background-color: rgba($color-blacktone01, 0.4);
	// 	transition: 1500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
	// 	transition-property: background-color;
	// }
}

.lnav__item-link-inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: inherit;
	padding: 1rem;
	text-align: center;
	color: $color-white;
}

.lnav__item-title {
	font-size: 2.2rem;
	@include line-height(26, 22);
	@include mq('max') {
		font-size: 1.9rem;
		@include line-height(24, 19);
	}
}



/*バナー
---------------------------------------------------------- */
.bnr__list {
	@include mq('min') {
		display: flex;
		flex-wrap: wrap;
		margin: -4.8rem 0 0 -4.8rem;
	}
}

.bnr__item {
	@include mq('min') {
		width: calc(33.3333% - 4.8rem);
		margin: 4.8rem 0 0 4.8rem;
	}
	@include mq('max') {
		&:not(:first-child) {
			margin-top: 3.2rem;
		}
	}
}

.bnr__item-link {
	display: flex;
	width: 100%;
	@include opacity();
}

.bnr__item-head {
	width: 12rem;
	@include mq('max') {
		width: 8rem;
	}
}

.bnr__item-body {
	flex: 1 1 0%;
	padding-left: 2rem;
	@include line-height(24, 16);
	@include mq('max') {
		padding-left: 1.5rem;
	}
}

.bnr__item-img {
	position: relative;
	height: 12rem;
	background-color: #939393;
	@include mq('max') {
		height: 8rem;
	}
}

.bnr__item-img-bg  {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
}

.bnr__item-title {
	margin-bottom: 0.8rem;
	font-size: 1.8rem;
	font-weight: 700;
	color: $color-main;
	@include mq('max') {
		margin-bottom: 0.4rem;
		font-size: 1.5rem;
	}
}
